.company-select-page {
  display: flex;
  min-height: 100vh;
  background-color: white;
  padding: 20px;
  align-items: center;
  justify-content: center;
  position: relative;
}

.language-selector {
  position: absolute;
  top: 20px;
  right: 20px;
  z-index: 1000;
}

.selected-language,
.language-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  background: white;
  border-radius: 4px;
  transition: background-color 0.2s;
}

.selected-language:hover,
.language-option:hover {
  background-color: #f5f5f5;
}

.selected-language img,
.language-option img {
  width: 24px;
  height: 16px;
  object-fit: cover;
}

.language-dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  margin-top: 4px;
  background: white;
  border-radius: 4px;
  box-shadow: 0 2px 8px rgba(0,0,0,0.1);
  overflow: hidden;
}

.language-option {
  white-space: nowrap;
}

.company-select-container {
  background: white;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.08);
  width: 100%;
  max-width: 500px;
  padding: 30px;
}

.company-select-header {
  text-align: center;
  margin-bottom: 30px;
}

.company-select-header img {
  width: 180px;
  margin-bottom: 20px;
}

.company-select-header h2 {
  color: #333;
  font-size: 24px;
  margin: 0;
}

.company-select-header p {
  color: #666;
  font-size: 16px;
  margin-top: 10px;
}

.companies-list {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.company-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border: 1px solid #eee;
  border-radius: 8px;
  transition: all 0.2s ease;
}

.company-item:hover {
  border-color: #f1c40f;
  background-color: rgba(241, 196, 15, 0.05);
}

.company-main {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex: 1;
}

.company-main:hover {
  transform: translateX(5px);
}

.company-icon {
  width: 40px;
  height: 40px;
  background-color: #2c3e50;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 18px;
  margin-right: 15px;
  flex-shrink: 0;
}

.company-info {
  flex: 1;
}

.company-name {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.company-id {
  font-size: 13px;
  color: #666;
  margin: 4px 0 0 0;
}

.manage-account-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: 1px solid #2c3e50;
  border-radius: 6px;
  background: transparent;
  color: #2c3e50;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;
  margin-left: 15px;
  white-space: nowrap;
}

.manage-account-btn:hover {
  background: rgba(44, 62, 80, 0.1);
}

.manage-account-btn svg {
  font-size: 16px;
}

@media (max-width: 600px) {
  .company-select-container {
    margin: 10px;
    padding: 20px;
  }
  
  .company-select-header img {
    width: 140px;
  }

  .manage-account-btn {
    padding: 6px 12px;
    font-size: 13px;
  }

  .language-selector {
    top: 10px;
    right: 10px;
    padding: 6px 10px;
  }

  .language-current {
    font-size: 13px;
  }
} 