.module-management {
  width: 100%;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
  padding: 40px;
  background-color: #ffffff;
  overflow-y: auto;
  position: relative;
}

.module-management h2 {
  color: #2c3e50;
  margin-bottom: 40px;
  font-size: 18px;
  font-weight: 600;
}

.description {
  color: #5a6776;
  font-size: 14px;
  margin-bottom: 2rem;
  line-height: 1.6;
  max-width: 800px;
}

.description-details {
  color: #5a6776;
  font-size: 12px;
  margin-bottom: 2rem;
  font-style: italic;
}

.modules-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  margin-bottom: 16px;
  padding: 0 20px;
}

.module-card {
  background: white;
  border-radius: 12px;
  padding: 1.5rem;
  box-shadow: 0 1px 3px rgba(0,0,0,0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s ease;
  border: 1px solid #e2e8f0;
  width: 100%;
}

.module-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0,0,0,0.1);
  background-color: #f9f9f9;
}

.module-info {
  flex: 1;
}

.module-info h3 {
  color: #2c3e50;
  font-size: 14px;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.module-info p {
  color: #5a6776;
  font-size: 12px;
}

/* Switch toggle estilizado */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 26px;
  margin-left: 1rem;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e2e8f0;
  transition: .3s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .3s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #2c3e50;
}

input:checked + .slider:before {
  transform: translateX(24px);
}

/* Estado de loading */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;
  color: #64748b;
  font-size: 1rem;
}

/* Estado de erro */
.error {
  background-color: #fee2e2;
  color: #dc2626;
  padding: 1rem;
  border-radius: 8px;
  margin: 1rem 0;
  font-size: 0.9rem;
}

/* Animação de fade in */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.module-card {
  animation: fadeIn 0.3s ease-out;
} 