.profile-container {
  padding: 40px 20px;
  max-width: 500px;
  margin: 0 auto;
  margin-top: 40px;
}

.profile-container h2 {
  color: #333;
  margin-bottom: 30px;
  text-align: center;
  font-size: 28px;
  font-weight: 600;
}

.profile-content {
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
}

.profile-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.info-group {
  padding: 20px;
  border-radius: 8px;
  background: #f8f9fa;
  transition: all 0.3s ease;
}

.info-group:hover {
  transform: translateY(-2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.info-group label {
  display: block;
  color: #6c757d;
  font-size: 0.9em;
  margin-bottom: 8px;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.info-group span {
  display: block;
  color: #212529;
  font-size: 1.1em;
  font-weight: 500;
}

.loading {
  text-align: center;
  padding: 40px;
  color: #6c757d;
  font-size: 1.1em;
} 