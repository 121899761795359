.sidebar {
  position: fixed;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  background: white;
  border-radius: 0 8px 8px 0;
  box-shadow: 2px 0 10px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  overflow: hidden;
  transition: width 0.3s ease;
  width: 50px;
  height: auto;
}

.sidebar:hover {
  width: 180px;
}

.sidebar-nav {
  display: flex;
  flex-direction: column;
  padding: 8px 0;
}

.nav-item {
  display: flex;
  align-items: center;
  padding: 10px 15px;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s;
  white-space: nowrap;
}

.nav-item .icon {
  min-width: 20px;
  display: flex;
  justify-content: center;
  font-size: 16px;
}

.nav-item .label {
  margin-left: 10px;
  opacity: 0;
  transition: opacity 0.3s ease;
}

.sidebar:hover .nav-item .label {
  opacity: 1;
}

.nav-item:hover {
  background-color: #f8f9fa;
}

.nav-item.active {
  background-color: white;
  color: #181c24;
  border-left: 3px solid #181c24;
}
