.home-container {
  width: 100%;
  min-height: 100vh;

  background-color: #ffffff;
  margin-top: 60px;
  padding-bottom: 60px;
  position: relative;
}

.widgets-container {
  width: 95%;
  max-width: 1400px;
  margin: 40px 0 0 3%;
  display: flex;
  gap: 20px;
  margin-left: 20px;
  margin-bottom: 40px;
}

.widget {
  background: white;
  border-radius: 12px;
  padding: 16px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.05);
  border: 1px solid #e1e5eb;
  height: auto;
  overflow-y: visible;
  transition: all 0.3s ease;
}

.widget:hover {
  box-shadow: 0 8px 24px rgba(44, 62, 80, 0.1);
}

.services {
  width: 45%;
  min-width: 400px;
  background-color: white;
}

.applications {
  width: 50%;
  min-width: 800px;
  min-height: fit-content;
  background: #ffffff;  /* Fundo branco puro */
  border: 1px solid #e1e5eb;
  box-shadow: 
    0 4px 16px rgba(44, 62, 80, 0.06),
    0 1px 3px rgba(44, 62, 80, 0.02);  /* Sombra mais sofisticada */
}

.home-container .widget h2 {
  color: #2c3e50;
  font-size: 18px;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 2px solid var(--primary);
  width: fit-content;
  display: flex;
  align-items: center;
  gap: 12px;
  font-weight: 600;
  letter-spacing: 0.3px;
}

.home-container .widget h2 svg {
  font-size: 20px;
  color: var(--primary);
  background: rgba(30, 58, 138, 0.1);
  padding: 8px;
  border-radius: 8px;
  box-sizing: content-box;
  transition: all 0.3s ease;
}

.widget:hover h2 svg {
  transform: translateY(-2px);
  background: rgba(30, 58, 138, 0.15);
}

/* Estilos para o widget de Serviços */
.services-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 16px;
  margin-bottom: 16px;
}

/* Importar os estilos dos cards da navbar */
.service-card {
  composes: solution-card from './Navbar.css';
}

.service-icon {
  composes: nav-icon from './Navbar.css';
}

/* Estilos para o widget de Aplicações */
.applications-header {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 24px;
}

/* Ajustes na tabela de aplicações */
.applications-table {
  background: transparent;
  border-radius: 12px;
  overflow: hidden;
  margin-top: 20px;
}

.applications-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.applications-table td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #e1e5eb;
  font-size: 12px;
  color: #5a6776;
  vertical-align: middle;
  background: #ffffff;  /* Fundo branco puro */
  transition: background 0.2s ease;
}

/* Ajustes específicos para cada coluna */
.applications-table td:nth-child(1) {
  width: 22%;
  min-width: 160px;
}

.applications-table td:nth-child(2) {
  width: 38%;
  min-width: 220px;
}

.applications-table td:nth-child(3) {
  width: 22%;
  min-width: 160px;
}

.applications-table td:nth-child(4) {
  width: 18%;
  min-width: 140px;
}

/* Ajustes nos tipos de aplicação e operação */
.application-type,
.operation-type {
  display: flex;
  align-items: center;
  gap: 8px;
  white-space: nowrap;
}

.application-type span,
.operation-type span {
  font-size: 12px;
  color: #2c3e50;
  font-weight: 500;
}

/* Ajuste no container de host */
.host-container {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px;
}

.host-container span {
  font-size: 12px;
  color: #2c3e50;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

/* Ajuste no status */
.status {
  display: inline-block;
  padding: 4px 12px;
  border-radius: 8px;
  font-size: 12px;
  font-weight: 500;
  text-align: center;
  min-width: 100px;
  white-space: nowrap;
}

.status.ativo { 
  background: rgba(21, 128, 61, 0.1);
  color: var(--success);
}

.status.monitorado { 
  background: rgba(3, 105, 161, 0.1);
  color: var(--info);
}

.status.em-alerta { 
  background: rgba(180, 83, 9, 0.1);
  color: var(--warning);
}

button {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-all {
  background: #2c3e50;
  color: white;
  width: 100%;
  padding: 12px;
  font-weight: 500;
  transition: all 0.3s ease;
}

.add-app {
  background: #2c3e50;
  color: white;
  font-weight: 500;
  font-size: 12px;
  transition: all 0.3s ease;
}

button:hover {
  opacity: 0.9;
  transform: translateY(-1px);
  background-color: #34495e;
}

.add-app:hover, .view-all:hover {
  background: #34495e;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(44, 62, 80, 0.2);
}

.greeting {
  font-size: 18px;
  margin-bottom: 10px;
  color: #2c3e50;
  text-align: left;
  margin-left: 20px;
}

/* Adicionar uma classe específica para o primeiro greeting */
.greeting:first-of-type {
  padding-top: 80px;
}

/* Estilos para os ícones de aplicação */
.icon-container {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  font-size: 14px;
}

/* Tipos de aplicação - cores sólidas */
.web-app {
  color: white;
  background-color: #3498db;  /* Azul vivo */
  box-shadow: 0 2px 8px rgba(52, 152, 219, 0.3);
}

.mobile-app {
  color: white;
  background-color: #9b59b6;  /* Roxo vivo */
  box-shadow: 0 2px 8px rgba(155, 89, 182, 0.3);
}

.api-app {
  color: white;
  background-color: #2ecc71;  /* Verde vivo */
  box-shadow: 0 2px 8px rgba(46, 204, 113, 0.3);
}

/* Ícones de operação - cores sólidas */
.black-box {
  color: white;
  background-color: #e74c3c;  /* Vermelho vivo */
  box-shadow: 0 2px 8px rgba(231, 76, 60, 0.3);
}

.grey-box {
  color: white;
  background-color: #3498db;  /* Azul vivo */
  box-shadow: 0 2px 8px rgba(52, 152, 219, 0.3);
}

.white-box {
  color: white;
  background-color: #2ecc71;  /* Verde vivo */
  box-shadow: 0 2px 8px rgba(46, 204, 113, 0.3);
}

/* Hover apenas para efeito de elevação */
.service-icon:hover,
.web-app:hover,
.mobile-app:hover,
.api-app:hover,
.black-box:hover,
.grey-box:hover,
.white-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
}

/* Estilos para o footer */
.footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: #ffffff;
  border-top: 1px solid #e1e5eb;
  padding: 12px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.footer-content {
  display: flex;
  align-items: center;
  gap: 24px;
  font-size: 12px;
  color: #5a6776;
}

.footer-links {
  display: flex;
  gap: 16px;
}

.footer-links a {
  color: #5a6776;
  text-decoration: none;
  transition: color 0.3s ease;
}

.footer-links a:hover {
  color: #2c3e50;
}

.footer-copyright {
  font-size: 12px;
  color: #5a6776;
}

/* Ajuste no container principal para acomodar o footer fixo */
.home-container {
  padding-bottom: 60px;
  min-height: calc(100vh - 60px);
}

/* Substituir todas as cores amarelas */

/* Botão de adicionar aplicação */
.add-application-btn {
  background-color: #2c3e50;
  color: white;
}

.add-application-btn:hover {
  background-color: #34495e;
}

/* Hover dos cards */
.company-item:hover {
  border-color: #2c3e50;
  background-color: rgba(44, 62, 80, 0.05);
}

/* Ícones e elementos de destaque */
.language-icon {
  color: #2c3e50;
}

.language-option:hover {
  background-color: rgba(44, 62, 80, 0.1);
}

/* Inputs e selects */
.form-group input:focus,
.form-group select:focus {
  border-color: #2c3e50;
  box-shadow: 0 0 0 2px rgba(44, 62, 80, 0.1);
}

/* Botões e elementos interativos */
.submit-btn {
  background: #2c3e50;
  color: white;
}

.submit-btn:hover {
  background: #34495e;
}

.select-selected:hover {
  border-color: #2c3e50;
}

.select-option.selected {
  background-color: rgba(44, 62, 80, 0.1);
}

/* Outros elementos que possam ter amarelo */
.operation-icon-container:hover {
  border-color: #2c3e50;
}

/* Título do menu dropdown com borda amarela */
.menu-dropdown h2 {
  color: #2c3e50;
  font-size: 18px;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 2px solid #2c3e50;
  width: fit-content;
}

/* Cores principais - versão mais vibrante */
:root {
  --primary: #1e3a8a;
  --primary-light: #2563eb;
  --success: #10b981;
  --warning: #f97316;
  --info: #0ea5e9;
  --purple: #8b5cf6;
}

/* Ajustar opacidades dos backgrounds para dar mais destaque */
.web-app,
.mobile-app,
.api-app,
.status.ativo,
.status.monitorado,
.status.em-alerta,
.black-box,
.grey-box,
.white-box {
  background-color: rgba(var(--color-rgb), 0.15); /* Aumentar opacidade do background */
}

/* Aumentar contraste dos cards */
.service-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 8px 24px rgba(37, 99, 235, 0.15);
  border-color: var(--primary);
  background-color: rgba(37, 99, 235, 0.02);
}

/* Melhorar transições */
.service-card,
.service-icon,
.status,
.icon-container {
  transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
}

/* Efeito hover mais pronunciado nos ícones */
.service-icon:hover,
.icon-container:hover {
  transform: translateY(-2px) scale(1.05);
}

/* Aumentar opacidade dos backgrounds dos ícones */
.service-icon {
  background: rgba(30, 58, 138, 0.25);  /* Aumentado para 0.25 */
}

.web-app {
  background-color: rgba(14, 165, 233, 0.25);
  color: #0ea5e9;  /* Azul mais vivo */
}

.mobile-app {
  background-color: rgba(139, 92, 246, 0.25);
  color: #8b5cf6;  /* Roxo mais vivo */
}

.api-app {
  background-color: rgba(16, 185, 129, 0.25);
  color: #10b981;  /* Verde mais vivo */
}

.black-box {
  background-color: rgba(249, 115, 22, 0.25);
  color: #f97316;  /* Laranja mais vivo */
}

.grey-box {
  background-color: rgba(14, 165, 233, 0.25);
  color: #0ea5e9;  /* Azul mais vivo */
}

.white-box {
  background-color: rgba(16, 185, 129, 0.25);
  color: #10b981;  /* Verde mais vivo */
}

/* Ajustar hover dos ícones */
.service-icon:hover,
.web-app:hover,
.mobile-app:hover,
.api-app:hover,
.black-box:hover,
.grey-box:hover,
.white-box:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.25);
}

/* Hover nas linhas da tabela */
.applications-table tr:hover td {
  background: #f8fafc;  /* Cinza muito claro no hover */
} 