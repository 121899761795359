.monitoring-container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  background: #f8f9fa;
  width: 100%;
}

.content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100vh;
  position: relative;
  -webkit-overflow-scrolling: touch;
}

.report-details-container {
  padding: 40px;
  min-height: calc(100vh - 80px);
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
}

.sticky-header {
  background: #f8f9fa;
  padding: 20px;
  transition: all 0.3s ease;
  width: 100%;
}

.sticky-header > * {
  pointer-events: auto;
}

.sticky-header.scrolled {
  background: rgba(248, 249, 250, 0.98);
  padding: 10px 20px;
}

.sticky-header.scrolled .report-title h2 {
  font-size: 20px;
  margin: 5px 0;
}

.sticky-header.scrolled .quick-info-grid {
  transform: scale(0.85);
  transform-origin: top left;
}

.sticky-header.scrolled .quick-info-card {
  height: 60px;
  padding: 8px 12px;
}

.sticky-header.scrolled .quick-info-card i {
  font-size: 16px;
}

.sticky-header.scrolled .quick-info-card .label {
  font-size: 12px;
}

.sticky-header.scrolled .quick-info-card .value {
  font-size: 14px;
}

@keyframes slideUp {
  0% {
    transform: translateY(100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.scrolled .report-title {
  display: none;
}

.scrolled .quick-info-grid {
  gap: 10px;
  margin: 0;
}

.scrolled .quick-info-card {
  height: 50px;
  padding: 8px;
}

.scrolled .card-icon {
  width: 20px;
  height: 20px;
}

.scrolled .card-icon i {
  font-size: 10px;
}

.scrolled .quick-info-card label {
  font-size: 9px;
  margin-bottom: 2px;
}

.scrolled .quick-info-card .value {
  font-size: 11px;
}

.main-content-wrapper {
  flex: 1;
  padding-top: 0;
  position: relative;
  z-index: 1;
}

.main-content-wrapper.header-scrolled {
  margin-top: 200px;
}

.details-container {
  display: grid;
  grid-template-columns: minmax(0, 75%) minmax(0, 25%);
  gap: 25px;
  margin-top: 5px;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
}

.card-content {
  display: flex;
  align-items: center;
  gap: 15px;
  z-index: 1;
}

.card-icon {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: rgba(44, 62, 80, 0.1);
  transition: all 0.3s ease;
}

.card-icon i {
  font-size: 18px;
  color: #2c3e50;
  transition: all 0.3s ease;
}

.quick-info-card:hover .card-icon {
  transform: scale(1.1) rotate(5deg);
}

.card-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.quick-info-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 4px;
  height: 100%;
  background: #2c3e50;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  overflow: hidden;
}

.quick-info-card::after {
  content: '';
  position: absolute;
  top: -100%;
  left: -100%;
  width: 300%;
  height: 300%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.1) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transition: all 0.6s ease;
  pointer-events: none;
}

.quick-info-card:hover::after {
  top: -50%;
  left: -50%;
}

.quick-info-card:hover {
  transform: translateY(-2px) scale(1.02);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  border-color: #2c3e50;
}

.quick-info-card:hover::before {
  opacity: 1;
}

.quick-info-card label {
  font-size: 11px;
  color: #6c757d;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-bottom: 8px;
  display: block;
  transition: color 0.3s ease;
  position: relative;
}

.quick-info-card:hover label {
  color: #2c3e50;
  transform: translateX(4px);
}

.quick-info-card .value {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 600;
  transition: all 0.3s ease;
  padding-left: 2px;
  position: relative;
}

.quick-info-card:hover .value {
  transform: translateX(4px);
}

.quick-info-card select {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 500;
  padding: 8px 12px;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
}

.quick-info-card select:hover {
  border-color: #2c3e50;
}

.quick-info-card select:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 0 2px rgba(44, 62, 80, 0.1);
}

.main-content {
  background: #ffffff;
  border-radius: 12px;
  padding: 30px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  height: fit-content;
  min-height: 300px;
}

.main-content h3 {
  color: #2c3e50;
  font-size: 20px;
  margin: 0 0 25px 0;
  padding-bottom: 15px;
  border-bottom: 1px solid #e9ecef;
  font-weight: 600;
}

.description {
  font-size: 15px;
  line-height: 1.8;
  color: #2c3e50;
  white-space: pre-wrap;
  padding: 25px;
  background: #f8f9fa;
  border-radius: 8px;
  margin-bottom: 35px;
}

.description:last-child {
  margin-bottom: 0;
}

.timeline {
  clear: both;
  background: #ffffff;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  height: fit-content;
  min-height: 300px;
  width: 100%;
}

.timeline h3 {
  color: #2c3e50;
  font-size: 18px;
  margin: 0 0 25px 0;
  padding-bottom: 12px;
  border-bottom: 1px solid #e9ecef;
  font-weight: 600;
}

.timeline-item {
  padding: 15px;
  border-bottom: 1px solid #eee;
  transition: background-color 0.2s;
}

.timeline-item:hover {
  background-color: #f8f9fa;
}

.timeline-item .time {
  color: #666;
  font-size: 0.85em;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 6px;
}

.timeline-item .time i {
  color: #95a5a6;
  font-size: 0.9em;
}

.timeline-item .event {
  color: #333;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.95em;
  flex-wrap: wrap;
}

.timeline-item .event i {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
  flex-shrink: 0;
  transition: all 0.2s ease;
}

.timeline-item .event i:hover {
  transform: scale(1.1);
}

.timeline-item .event i.fa-exchange-alt {
  background-color: #3498db15;
  color: #3498db;
}

.timeline-item .event i.fa-user-edit {
  background-color: #9b59b615;
  color: #9b59b6;
}

.timeline-item .event-text {
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-width: 200px;
}

.timeline-item .event-text strong {
  color: #2c3e50;
  font-weight: 600;
}

.timeline-item .event-author {
  display: flex;
  align-items: center;
  gap: 4px;
  color: #666;
  font-size: 0.9em;
  margin-left: auto;
}

.timeline .note {
  margin-top: 8px;
  padding: 12px 16px;
  background-color: #fff9e6;
  border-left: 3px solid #f1c40f;
  border-radius: 4px;
  font-size: 0.9em;
  color: #666;
  margin-left: 36px;
  position: relative;
  word-wrap: break-word;
}

.timeline .note i {
  color: #f1c40f;
  margin-right: 8px;
  font-size: 0.9em;
}

.impact-badge {
  display: inline-block;
  padding: 6px 16px;
  border-radius: 20px;
  color: white;
  font-size: 13px;
  font-weight: 600;
  letter-spacing: 1px;
  transition: all 0.3s ease;
  text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.impact-badge::after {
  content: '';
  position: absolute;
  top: -50%;
  left: -50%;
  width: 200%;
  height: 200%;
  background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  transform: rotate(45deg);
  transition: all 0.6s ease;
}

.impact-badge:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.impact-badge:hover::after {
  left: 100%;
}

/* Cores e estilos específicos para cada card */
.date-card::before {
  background: linear-gradient(to bottom, #3498db, #2980b9);
}

.category-card::before {
  background: linear-gradient(to bottom, #e74c3c, #c0392b);
}

.app-card::before {
  background: linear-gradient(to bottom, #2ecc71, #27ae60);
}

.id-card::before {
  background: linear-gradient(to bottom, #f1c40f, #f39c12);
}

.responsible-card::before {
  background: linear-gradient(to bottom, #9b59b6, #8e44ad);
}

.criticality-card::before {
  background: linear-gradient(to bottom, #e67e22, #d35400);
}

.quick-info-left {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.quick-info-right {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media (max-width: 1400px) {
  .details-container {
    grid-template-columns: 70% 30%;
  }
}

@media (max-width: 1200px) {
  .details-container {
    grid-template-columns: 1fr;
  }
  
  .quick-info-row {
    flex-wrap: wrap;
  }

  .quick-info-card {
    flex: 1 1 calc(50% - 20px);
    min-width: 200px;
  }

  .timeline {
    margin-top: 25px;
  }
}

@media (max-width: 768px) {
  .report-details-container {
    padding: 20px;
  }
  
  .quick-info-card {
    flex: 1 1 100%;
  }

  .quick-info-grid {
    grid-template-columns: 1fr;
  }

  .quick-info-card {
    height: 90px;
  }

  .report-title {
    padding: 20px 25px;
  }

  .report-title h2 {
    font-size: 22px;
  }
}

.responsible-select {
  font-size: 16px;
  color: #2c3e50;
  font-weight: 500;
  padding: 8px 12px;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  background-color: #fff;
  cursor: pointer;
  transition: all 0.2s ease;
  width: 100%;
}

.responsible-select:hover {
  border-color: #2c3e50;
}

.responsible-select:focus {
  outline: none;
  border-color: #2c3e50;
  box-shadow: 0 0 0 2px rgba(44, 62, 80, 0.1);
}

.user-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 6px 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  min-height: 36px;
}

.user-option:hover {
  background-color: #f8f9fa;
}

.user-initials {
  min-width: 24px;
  width: 24px;
  height: 24px;
  background-color: #2c3e50;
  color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 11px;
  font-weight: 600;
}

.user-name {
  font-size: 13px;
  color: #2c3e50;
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.custom-select {
  position: relative;
  width: 100%;
}

.select-selected {
  cursor: pointer;
  padding: 6px 8px;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  background: white;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}

.select-selected::after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  font-size: 12px;
  color: #6c757d;
  margin-left: 8px;
  transition: transform 0.2s ease;
}

.expanded .select-selected::after {
  transform: rotate(180deg);
}

.select-options {
  position: fixed;
  background: white;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 4px;
  width: 200px;
}

.select-options::-webkit-scrollbar {
  width: 8px;
}

.select-options::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.select-options::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.select-options::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

/* Cores específicas para os ícones de cada card */
.date-card .card-icon {
  background: rgba(52, 152, 219, 0.1);
}

.date-card .card-icon i {
  color: #3498db;
}

.category-card .card-icon {
  background: rgba(231, 76, 60, 0.1);
}

.category-card .card-icon i {
  color: #e74c3c;
}

.app-card .card-icon {
  background: rgba(46, 204, 113, 0.1);
}

.app-card .card-icon i {
  color: #2ecc71;
}

.id-card .card-icon {
  background: rgba(241, 196, 15, 0.1);
}

.id-card .card-icon i {
  color: #f1c40f;
}

.responsible-card .card-icon {
  background: rgba(155, 89, 182, 0.1);
}

.responsible-card .card-icon i {
  color: #9b59b6;
}

.criticality-card .card-icon {
  background: rgba(230, 126, 34, 0.1);
}

.criticality-card .card-icon i {
  color: #e67e22;
}

.main-content-wrapper {
  margin-top: 5px;
  padding-top: 5px;
}

.report-title {
  margin-top: 40px;
  margin-bottom: 30px;
  padding: 0 10px;
  transition: all 0.3s ease;
}

.quick-info-grid {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 5px;
  transition: all 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.quick-info-row {
  display: flex;
  gap: 20px;
  width: 100%;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.quick-info-card {
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border: 1px solid #f0f0f0;
  height: 80px;
  flex: 1;
  min-width: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
  overflow: visible;
  cursor: pointer;
}

.quick-info-card.expanded {
  height: auto;
  max-height: 250px;
  z-index: 1000;
}

.card-text {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.card-text label {
  font-size: 11px;
  color: #6c757d;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 0.7px;
  margin-bottom: 4px;
  display: block;
  transition: color 0.3s ease;
  position: relative;
}

.custom-select {
  position: relative;
  width: 100%;
}

.select-selected {
  cursor: pointer;
  padding: 6px 8px;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  background: white;
  height: 36px;
  display: flex;
  align-items: center;
}

.select-options {
  position: relative;
  background: white;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  max-height: 150px;
  overflow-y: auto;
  margin-top: 4px;
  width: 100%;
}

.user-option {
  display: flex;
  align-items: center;
  padding: 6px 8px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  height: 36px;
}

.user-option:hover {
  background-color: rgba(241, 196, 15, 0.1);
}

.user-initials {
  width: 24px;
  height: 24px;
  background-color: #f1c40f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 12px;
  margin-right: 8px;
  flex-shrink: 0;
}

.user-name {
  font-size: 13px;
  color: #333;
  flex: 1;
}

.scroll-to-top {
  position: fixed;
  bottom: 30px;
  right: 30px;
  background: #2c3e50;
  color: white;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.scroll-to-top.visible {
  opacity: 1;
  visibility: visible;
}

.scroll-to-top:hover {
  background: #34495e;
  transform: translateY(-2px);
}

.scroll-to-top i {
  font-size: 20px;
}

.management-button {
  position: relative;
  right: auto;
  top: auto;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s;
  margin-bottom: 20px;
  display: inline-flex;
  align-items: center;
  gap: 8px;
  float: right;
}

.management-button i {
  font-size: 16px;
}

.management-button:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 500px;
  max-width: 90%;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.popup-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.popup-header h2 {
  margin: 0;
  font-size: 20px;
  color: #333;
}

.close-button {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #6B778C;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.close-button:hover {
  background-color: #f4f5f7;
  color: #2c3e50;
  transform: scale(1.1);
}

.close-button i {
  font-size: 16px;
}

.status-select {
  width: 100%;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  margin-bottom: 16px;
  font-size: 14px;
  appearance: none;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.status-select:hover {
  border-color: #2684ff;
}

.status-select:focus {
  outline: none;
  border-color: #2684ff;
  box-shadow: 0 0 0 2px rgba(38, 132, 255, 0.2);
}

.status-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
}

.status-option i {
  font-size: 16px;
}

.status-option.new i { color: #2684ff; }
.status-option.under-review i { color: #6554C0; }
.status-option.confirmed i { color: #FF5630; }
.status-option.awaiting-fix i { color: #FFAB00; }
.status-option.in-mitigation i { color: #36B37E; }
.status-option.awaiting-retest i { color: #00B8D9; }
.status-option.retest-done i { color: #6554C0; }
.status-option.fixed i { color: #36B37E; }
.status-option.closed i { color: #6B778C; }

.notes-textarea {
  width: 100%;
  height: 150px;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 14px;
  resize: vertical;
}

.save-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  width: 100%;
  transition: background-color 0.3s;
}

.save-button:hover {
  background-color: #45a049;
}

.timeline-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 25px;
  padding-bottom: 12px;
  border-bottom: 1px solid #e9ecef;
}

.timeline-header h3 {
  color: #2c3e50;
  font-size: 18px;
  margin: 0;
  padding: 0;
  border: none;
  font-weight: 600;
}

.custom-select {
  position: relative;
  width: 100%;
}

.select-selected {
  cursor: pointer;
  padding: 8px;
  border: 1px solid #e9ecef;
  border-radius: 6px;
  background: white;
}

.select-options {
  position: fixed;
  background: white;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 9999;
  max-height: 200px;
  overflow-y: auto;
  margin-top: 4px;
  width: 200px;
}

.user-option {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.user-option:hover {
  background-color: rgba(241, 196, 15, 0.1);
}

.user-initials {
  width: 30px;
  height: 30px;
  background-color: #f1c40f;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-size: 14px;
  margin-right: 10px;
  flex-shrink: 0;
}

.user-name {
  font-size: 14px;
  color: #333;
  flex: 1;
}

.custom-status-select {
  position: relative;
  width: 100%;
  margin-bottom: 16px;
}

.status-selected {
  display: flex;
  align-items: center;
  padding: 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.status-selected:hover {
  border-color: #2684ff;
}

.status-selected::after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  margin-left: auto;
  color: #6B778C;
  transition: transform 0.3s ease;
}

.status-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
}

.status-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.status-option:hover {
  background-color: #f4f5f7;
}

.status-option i {
  font-size: 16px;
  width: 20px;
  text-align: center;
}

.status-option span {
  font-size: 14px;
  color: #172B4D;
}

/* Cores dos ícones para cada status */
.status-option.new i { color: #2684ff; }
.status-option.under-review i { color: #6554C0; }
.status-option.confirmed i { color: #FF5630; }
.status-option.awaiting-fix i { color: #FFAB00; }
.status-option.in-mitigation i { color: #36B37E; }
.status-option.awaiting-retest i { color: #00B8D9; }
.status-option.retest-done i { color: #6554C0; }
.status-option.fixed i { color: #36B37E; }
.status-option.closed i { color: #6B778C; }

/* Cores e ícones para cada tipo de status na timeline */
.timeline-item .event.new i { 
  background-color: #2684ff15;
  color: #2684ff; 
}

.timeline-item .event.under-review i { 
  background-color: #6554C015;
  color: #6554C0; 
}

.timeline-item .event.confirmed i { 
  background-color: #FF563015;
  color: #FF5630; 
}

.timeline-item .event.awaiting-fix i { 
  background-color: #FFAB0015;
  color: #FFAB00; 
}

.timeline-item .event.in-mitigation i { 
  background-color: #36B37E15;
  color: #36B37E; 
}

.timeline-item .event.awaiting-retest i { 
  background-color: #00B8D915;
  color: #00B8D9; 
}

.timeline-item .event.retest-done i { 
  background-color: #6554C015;
  color: #6554C0; 
}

.timeline-item .event.fixed i { 
  background-color: #36B37E15;
  color: #36B37E; 
}

.timeline-item .event.closed i { 
  background-color: #6B778C15;
  color: #6B778C; 
}

.timeline-item .event.responsible-change i {
  background-color: #9b59b615;
  color: #9b59b6;
}

.status-option i {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
  text-align: center;
  transition: all 0.2s ease;
}

/* Cores dos ícones para cada status */
.status-option.new i { 
  background-color: #2684ff15;
  color: #2684ff; 
}

.status-option.under-review i { 
  background-color: #6554C015;
  color: #6554C0; 
}

.status-option.confirmed i { 
  background-color: #FF563015;
  color: #FF5630; 
}

.status-option.awaiting-fix i { 
  background-color: #FFAB0015;
  color: #FFAB00; 
}

.status-option.in-mitigation i { 
  background-color: #36B37E15;
  color: #36B37E; 
}

.status-option.awaiting-retest i { 
  background-color: #00B8D915;
  color: #00B8D9; 
}

.status-option.retest-done i { 
  background-color: #6554C015;
  color: #6554C0; 
}

.status-option.fixed i { 
  background-color: #36B37E15;
  color: #36B37E; 
}

.status-option.closed i { 
  background-color: #6B778C15;
  color: #6B778C; 
}

.status-option:hover i {
  transform: scale(1.1);
}

/* Cores dos ícones baseadas na criticidade */
.card-icon .icon.critical {
  color: #dc3545;
  background-color: #dc354515;
}

.card-icon .icon.high {
  color: #fd7e14;
  background-color: #fd7e1415;
}

.card-icon .icon.medium {
  color: #ffc107;
  background-color: #ffc10715;
}

.card-icon .icon.low {
  color: #28a745;
  background-color: #28a74515;
}

.card-icon i {
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 14px;
  transition: all 0.2s ease;
}

/* Cores dos ícones para o card de status */
.status-card .card-icon .icon.new { 
  background-color: #2684ff15;
  color: #2684ff; 
}

.status-card .card-icon .icon.under-review { 
  background-color: #6554C015;
  color: #6554C0; 
}

.status-card .card-icon .icon.confirmed { 
  background-color: #FF563015;
  color: #FF5630; 
}

.status-card .card-icon .icon.awaiting-fix { 
  background-color: #FFAB0015;
  color: #FFAB00; 
}

.status-card .card-icon .icon.in-mitigation { 
  background-color: #36B37E15;
  color: #36B37E; 
}

.status-card .card-icon .icon.awaiting-retest { 
  background-color: #00B8D915;
  color: #00B8D9; 
}

.status-card .card-icon .icon.retest-done { 
  background-color: #6554C015;
  color: #6554C0; 
}

.status-card .card-icon .icon.fixed { 
  background-color: #36B37E15;
  color: #36B37E; 
}

.status-card .card-icon .icon.closed { 
  background-color: #6B778C15;
  color: #6B778C; 
} 