.totp-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f9fa;
  padding: 20px;
}

.totp-card {
  background: white;
  border-radius: 12px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 30px;
  width: 100%;
  max-width: 400px;
}

.totp-header {
  text-align: center;
  margin-bottom: 30px;
}

.shield-icon {
  font-size: 48px;
  color: #f1c40f;
  margin-bottom: 20px;
}

.lock-icon {
  margin-right: 8px;
  color: #f1c40f;
}

.totp-header h2 {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #2c3e50;
  margin: 0;
  font-size: 24px;
}

.totp-description {
  display: flex;
  align-items: flex-start;
  gap: 10px;
  color: #666;
  margin-top: 15px;
  font-size: 15px;
  line-height: 1.5;
  text-align: left;
  padding: 0 10px;
}

.mobile-icon {
  color: #3498db;
  font-size: 18px;
  margin-top: 3px;
}

.qr-section {
  background: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin: 20px 0;
}

.qr-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.qr-icon {
  color: #2ecc71;
  font-size: 24px;
}

.qr-code {
  display: block;
  margin: 0 auto;
  max-width: 200px;
  height: auto;
}

.totp-form {
  margin-top: 30px;
}

.form-header {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 15px;
}

.key-icon {
  color: #e67e22;
  font-size: 20px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group input {
  width: 100%;
  padding: 12px;
  border: 2px solid #ddd;
  border-radius: 6px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.form-group input:focus {
  border-color: #f1c40f;
  outline: none;
}

.error-message {
  color: #e74c3c;
  margin: 10px 0;
  padding: 10px;
  background: #fdeaea;
  border-radius: 4px;
  font-size: 14px;
}

.verify-button {
  width: 100%;
  padding: 12px;
  background: #f1c40f;
  border: none;
  border-radius: 6px;
  color: #2c3e50;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.3s;
}

.verify-button:hover:not(:disabled) {
  background: #f39c12;
}

.verify-button:disabled {
  background: #ddd;
  cursor: not-allowed;
}

@media (max-width: 480px) {
  .totp-card {
    padding: 20px;
  }

  .shield-icon {
    font-size: 36px;
  }

  .totp-header h2 {
    font-size: 20px;
  }
} 