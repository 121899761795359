.navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #181c24;
    padding: 2.3px 20px;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 1000;
    box-shadow: 0 2px 4px rgba(182, 179, 179, 0.1);
  }
  
  .navbar-left {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .navbar-logo {
    height: 34.5px;
  }
  
  .navbar-right {
    position: relative;
  }
  
  .navbar-user {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: white;
    gap: 8px;
    font-size: 14px;
  }
  
  .dropdown-icon {
    color: white;
    font-size: 10px;
  }
  
  .navbar-dropdown-content {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    min-width: 160px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    z-index: 1;
  }
  
  .navbar-dropdown-content a {
    color: #333;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .navbar-dropdown-content a:hover {
    background-color: #f5f5f5;
  }
  
  .navbar-menu {
    height: 20px;
    margin-left: 10px;
    cursor: pointer;
  }
  
  .menu-container {
    position: relative;
  }
  
  .menu-dropdown {
    position: absolute;
    top: 100%;
    left: 0;
    background-color: white;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 2px 4px rgba(0,0,0,0.05);
    border: 1px solid #f3ecec;
    width: 800px;
    z-index: 1000;
    margin-top: 0;
  }
  
  .menu-container::after {
    content: '';
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    height: 20px;
    background: transparent;
  }
  
  .menu-dropdown h2 {
    color: #2c3e50;
    font-size: 18px;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 2px solid var(--primary);
    width: fit-content;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .menu-dropdown h2 svg {
    color: var(--primary);
    font-size: 20px;
  }
  
  .solutions-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 12px;
    padding: 12px;
  }
  
  .solution-card {
    padding: 16px;
    border-radius: 8px;
    background: white;
    transition: all 0.2s ease;
    text-decoration: none;
    color: inherit;
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin: 4px;
    width: 240px;
    height: 160px;
    position: relative;
    overflow: hidden;
  }
  
  .solution-card:hover {
    transform: translateY(-2px);
    box-shadow: 0 4px 6px rgba(0,0,0,0.1);
    background-color: #f9f9f9;
  }
  
  .solution-icon {
    color: white;
    font-size: 18px;
    transition: all 0.3s ease;
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    flex-shrink: 0;
    backdrop-filter: blur(8px);
  }
  
  .solution-card:hover .solution-icon {
    transform: translateY(-2px);
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    filter: brightness(1.1);
  }
  
  .solution-card h3 {
    font-size: 14px;
    font-weight: 500;
    color: #2c3e50;
    margin: 0;
    white-space: nowrap;
  }
  
  .solution-info {
    display: flex;
    flex-direction: column;
    gap: 8px;
    flex: 1;
    padding-bottom: 8px;
  }
  
  .solution-card p {
    font-size: 12px;
    color: #5a6776;
    margin: 0;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    line-height: 1.3;
    max-height: 32px;
    margin-bottom: 8px;
  }
  
  .solution-status {
    font-size: 9px;
    padding: 4px 8px;
    border-radius: 12px;
    font-weight: 500;
    letter-spacing: 0.5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin-top: auto;
    position: static;
  }
  
  .solution-status.active {
    background-color: rgba(46, 204, 113, 0.08);
    color: #2ecc71;
    border: 1px solid rgba(46, 204, 113, 0.15);
  }
  
  .solution-status.inactive {
    background-color: rgba(189, 195, 199, 0.08);
    color: #a0a7ac;
    border: 1px solid rgba(189, 195, 199, 0.15);
  }
  
  .solution-status::before {
    content: '';
    width: 4px;
    height: 4px;
    border-radius: 50%;
    margin-right: 4px;
  }
  
  .solution-status.active::before {
    background-color: #2ecc71;
  }
  
  .solution-status.inactive::before {
    background-color: #a0a7ac;
  }
  
  .company-dropdown {
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    min-width: 220px;
    z-index: 1000;
    overflow: hidden;
    margin-top: 8px;
    border: 1px solid rgba(0, 0, 0, 0.08);
  }
  
  .dropdown-actions {
    padding: 8px;
  }
  
  .dropdown-action-btn {
    width: 100%;
    padding: 10px 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    background: none;
    border: none;
    cursor: pointer;
    color: #333;
    font-size: 14px;
    text-align: left;
    border-radius: 6px;
    transition: all 0.2s ease;
  }
  
  .dropdown-action-btn:hover {
    background-color: #f5f5f5;
  }
  
  .dropdown-action-btn svg {
    font-size: 16px;
    color: #666;
  }
  
  .dropdown-action-btn.logout {
    color: #dc3545;
  }
  
  .dropdown-action-btn.logout svg {
    color: #dc3545;
  }
  
  .dropdown-action-btn.logout:hover {
    background-color: #fff5f5;
  }
  
  /* Reset completo dos estilos dos ícones da navbar */
  .nav-link {
    text-decoration: none;
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 10px;
    transition: all 0.3s ease;
  }
  
  /* Estilos para os ícones da navbar */
  .nav-icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    transition: all 0.3s ease;
    font-size: 16px;
    color: white;
  }
  
  /* Cores específicas para cada módulo inspiradas nos serviços AWS */
  
  /* Gerenciamento de Módulos - Inspirado no AWS Systems Manager */
  .nav-icon[data-module="module_management"],
  .solution-icon[data-module="module_management"] {
    background-color: #01A88D !important;  /* Verde-azulado AWS Systems Manager */
  }
  
  /* Aplicações - Inspirado no AWS App Services */
  .nav-icon[data-module="applications"],
  .solution-icon[data-module="applications"] {
    background-color: #3F5BC6 !important;  /* Azul vibrante AWS App Services */
  }
  
  /* Monitoramento - Inspirado no AWS CloudWatch */
  .nav-icon[data-module="monitoring"],
  .solution-icon[data-module="monitoring"] {
    background-color: #00A1C9 !important;  /* Azul claro AWS CloudWatch */
  }
  
  /* Pentest - Inspirado no AWS WAF */
  .nav-icon[data-module="pentest"],
  .solution-icon[data-module="pentest"] {
    background-color: #D86613 !important;  /* Laranja AWS WAF */
  }
  
  /* Bug Bounty - Inspirado no AWS Shield */
  .nav-icon[data-module="bugbounty"],
  .solution-icon[data-module="bugbounty"] {
    background-color: #C7511F !important;  /* Vermelho-laranja AWS Shield */
  }
  
  /* Code Scanning - Inspirado no AWS CodeGuru */
  .nav-icon[data-module="code_scanning"],
  .solution-icon[data-module="code_scanning"] {
    background-color: #4D27AA !important;  /* Roxo AWS CodeGuru */
  }
  
  /* Cloud Security - Inspirado no AWS Security Hub */
  .nav-icon[data-module="cloud_security"],
  .solution-icon[data-module="cloud_security"] {
    background-color: #02A7F0 !important;  /* Azul AWS Security Hub */
  }
  
  /* Efeitos de hover e clique */
  .navbar-left .nav-link:hover .nav-icon {
    transform: translateY(-2px);
    filter: brightness(1.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  }
  
  .navbar-left .nav-link:active .nav-icon {
    transform: translateY(1px);
    filter: brightness(0.95);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  }

  /* Estilos para os cards de solução no dropdown */
  .solution-card .solution-icon {
    width: 36px;
    height: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    color: white;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
  }

  /* Cores específicas para cada card de solução */
  .solution-card:nth-child(1) .solution-icon,
  .solution-card:nth-child(2) .solution-icon,
  .solution-card:nth-child(3) .solution-icon,
  .solution-card:nth-child(4) .solution-icon {
    background-color: inherit;
  }