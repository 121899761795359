footer.footer {
  background-color: #181c24 !important;
  padding: 6px 24px;
  font-size: 11px;
  color: #ffffff !important;
  border-top: 1px solid #333333;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
  height: 26px;
  line-height: 1.2;
  font-weight: normal;
}

footer.footer p {
  margin: 0;
  color: #ffffff;
  font-size: 11px;
  white-space: nowrap;
  font-weight: normal;
}

footer.footer .footer-links {
  display: flex;
  gap: 10px;
}

footer.footer .footer-links a {
  color: #ffffff;
  text-decoration: none;
  transition: color 0.3s ease;
  font-size: 11px;
  white-space: nowrap;
  font-weight: normal;
}

footer.footer .footer-links a:hover {
  color: #f1c40f;
} 