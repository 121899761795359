.insights-container {
  padding: 20px;
  height: 100%;
  margin-left: 50px;
}

.insights-cards {
  display: flex;
  gap: 20px;
  margin-top: 20px;
  height: calc(100% - 60px);
  flex-wrap: wrap;
}

.insight-card {
  flex: 1;
  min-width: 400px;
  max-width: calc(50% - 10px);
  background: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  display: flex;
  flex-direction: column;
  height: 500px;
}

.insight-card:last-child {
  flex: 2;
  max-width: 800px;
}

@media (max-width: 1200px) {
  .insight-card {
    max-width: 100%;
  }
  
  .insight-card:last-child {
    max-width: 100%;
  }
}

.card-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.total-count {
  font-size: 14px;
  color: #666;
  background: #f5f5f5;
  padding: 4px 8px;
  border-radius: 4px;
}

.insight-card h3 {
  margin: 0 0 20px 0;
  color: #333;
  font-size: 1.2rem;
}

.chart-wrapper {
  flex: 1;
  position: relative;
  height: 300px;
  width: 95%;
  max-width: 95%;
  overflow: visible;
  padding-right: 20px;
  margin: 0 auto;
}

.chart-wrapper canvas {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  height: 100% !important;
  width: 100% !important;
}

.legend {
  margin-top: 20px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;
}

.legend-item {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.9rem;
}

.dot {
  width: 12px;
  height: 12px;
  border-radius: 50%;
}

.dot.critical { background-color: #dc3545; }
.dot.high { background-color: #fd7e14; }
.dot.medium { background-color: #ffc107; }
.dot.low { background-color: #28a745; }

.loading {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 400px;
}

.spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #181c24;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 16px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.error-message {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  min-height: 400px;
  color: #dc3545;
  text-align: center;
  padding: 20px;
}

.error-message p {
  background-color: #fff5f5;
  padding: 16px 24px;
  border-radius: 8px;
  border: 1px solid #dc3545;
  font-size: 16px;
} 