/* Reset básico para remover margens e preenchimentos padrão */
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}
  
html, body {
    width: 100%;
    height: 100%;
    overflow: hidden;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
  
body {
    justify-content: center;
    align-items: center;
    background-color: #ffffff;
    color: #333;
}
  
.login-page {
    display: flex;
    width: 100%;
    height: 100vh;
}
  
.image-container {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
  
.background-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    display: block;
}
  
.login-container {
    flex: 0 0 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
    min-width: 300px;
}
  
.login-card {
    background-color: #fff;
    padding: 3rem;
    border-radius: 16px;
    box-shadow: 0 10px 25px rgba(0, 0, 0, 0.03);
    text-align: center;
    width: 100%;
    max-width: 400px;
    min-height: 450px;
}
  
.logo {
    width: 150px;
    margin-bottom: 1.5rem;
}
  
h2 {
    font-size: 1.2rem;
    color: #2c3e50;
    margin-bottom: 1.5rem;
}
  
.login-form {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
}
  
.input-group {
    position: relative;
    margin-bottom: 1rem;
}
  
.input-group input {
    width: 100%;
    padding: 12px 14px;
    padding-right: 36px;
    background-color: #f8fafc;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 14px;
    transition: all 0.2s ease;
    color: #1e293b;
}

.input-group input:hover {
    border-color: #cbd5e1;
    background-color: #ffffff;
}

.input-group input:focus {
    border-color: #f1c40f;
    background-color: #ffffff;
    outline: none;
    box-shadow: 0 0 0 3px rgba(241, 196, 15, 0.1);
}
  
.input-group svg {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
  
/* Remover os estilos desses botões já que não serão mais usados */
.forgot-password, .contact-us {
    display: none;
}
  
/* Esconder o parágrafo que contém o texto "Não tem uma conta?" */
.login-card p {
    display: none;
}
  
/* Estilo mais discreto para o botão */
.login-button {
    width: 100%;
    padding: 12px;
    margin-top: 12px;
    background: #2c3e50; /* Azul escuro mais discreto */
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    position: relative;
}
  
.login-button:hover {
    transform: translateY(-1px);
    background: #34495e; /* Tom um pouco mais claro no hover */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
  
.login-button:active {
    transform: translateY(1px);
    background: #2c3e50;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}
  
.login-button::before {
    display: none;
}
  
.login-button:disabled {
    background: #94a3b8;
    cursor: not-allowed;
    transform: none;
    opacity: 0.7;
}
  
.login-button:disabled:hover {
    transform: none;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}
  
/* Ajustar o footer para ficar mais próximo do botão */
footer {
    margin-top: 1.5rem; /* Reduzido de 3rem para 1.5rem */
    font-size: 0.8rem;
    color: #94a3b8;
}
  
.error-toast {
    position: fixed;
    bottom: 16px;
    right: 16px;
    background-color: #ef4444;
    color: white;
    padding: 12px 16px;
    border-radius: 8px;
    font-size: 14px;
    animation: fadeIn 0.3s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 9999;
}
  
@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(3px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
  
.form-group input[inputmode="numeric"] {
    text-align: center;
    letter-spacing: 4px;
    font-size: 24px;
    padding: 12px;
    margin-bottom: 20px;
}
  
.totp-message {
    margin-bottom: 24px;
    text-align: center;
}

.totp-message h3 {
    font-size: 1.2rem;
    color: #2c3e50;
    margin-bottom: 1.5rem;
    font-weight: 600;
}

.totp-message p {
    font-size: 0.85rem;
    color: #64748b;
    display: block !important;
    line-height: 1.4;
    max-width: 280px;
    margin: 0 auto;
}
  
.totp-setup {
    margin: 24px 0;
    text-align: center;
}

.qr-code-container {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    position: relative;
}

.qr-code-container img {
    width: 200px;
    height: 200px;
    display: block;
    background: white;
}

.totp-info {
    position: absolute;
    top: 0;
    right: 0;
    width: 24px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: white;
    color: #64748b;
    cursor: help;
    transition: all 0.2s ease;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.totp-info:hover {
    color: #2c3e50;
    transform: translateY(-1px);
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.totp-info:hover .totp-tooltip {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.totp-tooltip {
    position: absolute;
    bottom: calc(100% + 12px);
    right: -8px;
    background: #334155;
    color: white;
    padding: 10px 14px;
    border-radius: 8px;
    font-size: 0.85rem;
    white-space: normal;
    opacity: 0;
    visibility: hidden;
    transform: translateY(5px);
    transition: all 0.2s ease;
    width: 240px;
    z-index: 10;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: left;
    line-height: 1.4;
}

.totp-tooltip::after {
    content: '';
    position: absolute;
    top: 100%;
    right: 10px;
    border-width: 6px;
    border-style: solid;
    border-color: #334155 transparent transparent transparent;
}

.totp-inputs-container {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin: 32px 0;
}

.totp-inputs-container input {
    width: 40px;
    height: 48px;
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    border: 2px solid #e2e8f0;
    border-radius: 8px;
    background-color: #f8fafc;
    transition: all 0.2s ease;
    color: #1e293b;
}

.totp-inputs-container input:focus {
    border-color: #2c3e50;
    background-color: #ffffff;
    outline: none;
    box-shadow: 0 0 0 3px rgba(44, 62, 80, 0.1);
}

.totp-inputs-container input:hover {
    border-color: #cbd5e1;
    background-color: #ffffff;
}

/* Remover spinners dos inputs numéricos */
.totp-inputs-container input::-webkit-outer-spin-button,
.totp-inputs-container input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

.totp-inputs-container input[type=number] {
    -moz-appearance: textfield;
}

.totp-title-info {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    margin-left: 8px;
    color: #64748b;
    cursor: help;
    position: relative;
    font-size: 16px;
}

.totp-title-info:hover {
    color: #2c3e50;
}

.totp-title-info:hover .totp-tooltip {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
}

.totp-title-info .totp-tooltip {
    bottom: auto;
    top: calc(100% + 12px);
    right: auto;
    left: 50%;
    transform: translateX(-50%) translateY(-5px);
    width: 300px;
}

.totp-title-info .totp-tooltip::after {
    top: auto;
    bottom: 100%;
    right: auto;
    left: 50%;
    transform: translateX(-50%);
    border-color: transparent transparent #334155 transparent;
}
  