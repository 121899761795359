* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  width: 100%;
}

.monitoring-container {
  display: flex;
  width: 100%;
  min-height: 100vh;
  background-color: #ffffff;
}

.content {
  flex: 1;
}

.dashboard-container,
.insights-container,
.reports-container {
  width: 100%;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
  padding: 40px;
  background-color: #ffffff;
  overflow-y: auto;
  position: relative;
}

.monitoring-container .content {
  margin-left: 0;
}

.monitoring-stats,
.insights-cards,
.applications-grid {
  width: 100%;
  margin: 0 auto;
}

.monitoring-container h2 {
  color: #2c3e50;
  margin-bottom: 40px;
  padding-bottom: 12px;
  width: fit-content;
  font-size: 24px;
}

.monitoring-stats {
  display: flex;
  justify-content: center;
  gap: 32px;
  margin: 40px 0;
  padding: 0 20px;
}

.stat-card {
  background: white;
  padding: 32px;
  border-radius: 16px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.1);
  border: 1px solid #e1e5eb;
  width: 380px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 24px rgba(0,0,0,0.15);
}

.stat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.stat-header h3 {
  font-size: 22px;
  font-weight: 600;
  color: #2c3e50;
}

.stat-badge {
  padding: 6px 12px;
  border-radius: 20px;
  color: white;
  font-size: 16px;
  font-weight: 600;
}

.stat-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 30px;
}

.stat-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
}

.stat-label {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #5a6776;
  font-size: 16px;
}

.stat-dot {
  font-size: 28px;
  line-height: 0;
}

.stat-value {
  font-size: 18px;
  font-weight: 600;
}

.progress-bar {
  height: 10px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  background-color: #f5f6fa;
}

.progress-segment {
  height: 100%;
  transition: width 0.3s ease;
}

.applications-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.app-card {
  background: white;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
  border: 1px solid #e1e5eb;
  transition: transform 0.3s ease;
}

.app-card:hover {
  transform: translateY(-5px);
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.app-header h3 {
  color: #2c3e50;
  margin: 0;
}

.app-info {
  margin-bottom: 20px;
}

.app-info p {
  margin: 8px 0;
  color: #5a6776;
}

.app-metrics {
  display: flex;
  justify-content: space-around;
  padding-top: 15px;
  border-top: 1px solid #e1e5eb;
}

.metric {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.metric-value {
  font-size: 20px;
  font-weight: 600;
  color: #2c3e50;
}

.metric-label {
  font-size: 12px;
  color: #5a6776;
}

.overall-stats {
  display: flex;
  justify-content: space-between;
  gap: 24px;
  margin-bottom: 40px;
  padding: 0 20px;
}

.overall-stat-card {
  background: white;
  padding: 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.08);
  border: 1px solid #e1e5eb;
  width: calc(33.33% - 16px);
  display: flex;
  align-items: center;
  gap: 20px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.overall-stat-card:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 16px rgba(0,0,0,0.12);
}

.overall-stat-icon {
  font-size: 32px;
  padding: 16px;
  background: #f8f9fa;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.overall-stat-info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.overall-stat-value {
  font-size: 28px;
  font-weight: 600;
  color: #2c3e50;
}

.overall-stat-title {
  font-size: 14px;
  color: #5a6776;
  font-weight: 500;
}

.content h2 {
  color: #2c3e50;
  font-size: 18px;
  margin-bottom: 16px;
  padding-bottom: 8px;
  width: fit-content;
  border-bottom: none;
}

.widget h2 {
  border-bottom: 2px solid #2c3e50;
} 