.applications-container {
  padding: 20px;
  width: 100%;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
  padding: 40px;
  background-color: #ffffff;
  overflow-y: auto;
  position: relative;
}

.applications-container h2 {
  color: #2c3e50;
  font-size: 20px;
  margin-bottom: 20px;
}

.button-container {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 20px;
}

.add-application-btn {
  background-color: #2c3e50;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  cursor: pointer;
  font-weight: 500;
  font-size: 13px;
  transition: all 0.3s ease;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.add-application-btn:hover {
  background-color: #34495e;
  transform: translateY(-1px);
}

.applications-table {
  background: #ffffff;
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  margin-top: 20px;
}

.applications-table table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.applications-table th,
.applications-table td {
  padding: 16px 24px;
  text-align: center;
  border-bottom: 1px solid #e1e5eb;
  font-size: 14px;
}

.applications-table th {
  background-color: #f8f9fa;
  font-weight: 600;
  color: #2c3e50;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  padding: 20px 16px;
}

.applications-table td {
  color: #5a6776;
  font-weight: 500;
  font-size: 14px;
  padding: 16px 24px;
  text-align: left;
}

.applications-table tbody tr {
  transition: all 0.2s ease;
}

.applications-table tbody tr:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

/* Estilos para o status */
.status {
  padding: 6px 12px;
  border-radius: 20px;
  font-size: 14px;
  font-weight: 500;
  text-transform: capitalize;
  letter-spacing: 0.5px;
  display: inline-flex;
  min-width: 100px;
  justify-content: center;
  margin: 0;
}

.status.active {
  background-color: #dcf5e8;
  color: #0a6634;
}

.status.inactive {
  background-color: #f1f2f4;
  color: #6c757d;
}

.status.monitoring {
  background-color: #e3f2fd;
  color: #0d47a1;
}

/* Estilos para operação */
.applications-table td:nth-child(3) {
  text-transform: capitalize;
}

/* Estilos para o host */
.host-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 4px 8px;
  border-radius: 6px;
  width: auto;
}

.host-container:hover {
  background-color: transparent;
  transform: none;
  box-shadow: none;
}

.host-favicon {
  width: 20px;
  height: 20px;
  min-width: 20px;
  object-fit: contain;
  border-radius: 4px;
  display: inline-block;
  vertical-align: middle;
}

.host-container span {
  font-size: 14px;
  color: #5a6776;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 300px;
}

/* Garantir que a célula do host esteja centralizada */
.applications-table td {
  text-align: center;
}

.applications-table td:nth-child(2) {
  text-align: center;
}

/* Remover margens automáticas */
.host-container {
  margin: 0 auto;
}

/* Loading state */
.loading {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  font-size: 16px;
  color: #666;
  font-weight: 500;
}

/* Estilos para os tipos de aplicação */
.application-type {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin: 0;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 36px;
  height: 36px;
  border-radius: 8px;
  transition: all 0.3s ease;
  background-color: #f8f9fa;
  border: 1px solid #e9ecef;
}

.icon-container svg {
  width: 20px;
  height: 20px;
  color: #5a6776;
  transition: all 0.3s ease;
}

.icon-container.web-app {
  background-color: #f8f9fa;
}

.icon-container.mobile-app {
  background-color: #f8f9fa;
}

.icon-container.api-app {
  background-color: #f8f9fa;
}

/* Remover todos os efeitos de hover dos ícones */
.icon-container:hover {
  transform: none;
  background-color: #f8f9fa;
  box-shadow: none;
  border-color: #e9ecef;
}

.icon-container:hover svg {
  color: #5a6776;
}

/* Remover os gradientes e efeitos de hover específicos */
.icon-container.web-app:hover,
.icon-container.mobile-app:hover,
.icon-container.api-app:hover {
  background: #f8f9fa;
  transform: none;
  box-shadow: none;
}

/* Remover o efeito de scale no hover */
.application-type:hover .application-icon {
  transform: none;
}

.application-type span {
  color: #5a6776;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

/* Estilos para operação */
.operation-type {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  margin: 0;
}

/* Estilos base para todos os tipos de operação */
.operation-icon-container,
.operation-icon-container.black-box,
.operation-icon-container.grey-box,
.operation-icon-container.white-box {
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: white;
  transition: all 0.3s ease;
  border: 1px solid #e9ecef;
  box-shadow: none;
}

/* Remover efeitos de hover para todos os tipos */
.operation-icon-container:hover,
.operation-icon-container.black-box:hover,
.operation-icon-container.grey-box:hover,
.operation-icon-container.white-box:hover {
  transform: none;
  box-shadow: none;
  background-color: white;
  border: 1px solid #e9ecef;
}

.operation-icon-container svg {
  width: 20px;
  height: 20px;
  color: #5a6776;
}

/* Estilo do tooltip customizado */
.operation-icon-container::before {
  content: attr(title);
  position: absolute;
  bottom: calc(100% + 10px);
  left: 50%;
  transform: translateX(-50%);
  padding: 8px 12px;
  background-color: #2c3e50;
  color: white;
  font-size: 12px;
  border-radius: 6px;
  white-space: nowrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  font-weight: 500;
  letter-spacing: 0.3px;
}

/* Seta do tooltip */
.operation-icon-container::after {
  content: '';
  position: absolute;
  bottom: calc(100% + 2px);
  left: 50%;
  transform: translateX(-50%);
  border: 8px solid transparent;
  border-top-color: #2c3e50;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease;
  z-index: 1000;
}

/* Mostrar tooltip no hover */
.operation-icon-container:hover::before,
.operation-icon-container:hover::after {
  opacity: 1;
  visibility: visible;
}

.operation-type span {
  color: #5a6776;
  text-transform: capitalize;
  font-size: 14px;
  font-weight: 500;
}

/* Garantir que todas as células mantenham o alinhamento à esquerda */
.applications-table td:nth-child(2),
.applications-table td:nth-child(4) {
  text-align: center;
}

/* Remover margens automáticas */
.host-container,
.status {
  margin: 0;
}

/* Estilos do Modal */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background: white;
  padding: 24px;
  border-radius: 12px;
  width: 100%;
  max-width: 500px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
}

.modal-content h3 {
  color: #2c3e50;
  font-size: 18px;
  margin-bottom: 24px;
  font-weight: 500;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #5a6776;
  font-size: 14px;
  font-weight: 500;
}

.form-group input,
.form-group select {
  width: 100%;
  padding: 10px 12px;
  border: 1px solid #e1e5eb;
  border-radius: 6px;
  font-size: 14px;
  color: #2c3e50;
  background-color: #ffffff;
  transition: all 0.2s ease;
}

.form-group input:focus,
.form-group select:focus {
  outline: none;
  border-color: #f1c40f;
  box-shadow: 0 0 0 2px rgba(241, 196, 15, 0.1);
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 24px;
}

.cancel-btn {
  padding: 8px 16px;
  border: 1px solid #e1e5eb;
  border-radius: 6px;
  background: white;
  color: #5a6776;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.submit-btn {
  padding: 8px 16px;
  border: none;
  border-radius: 6px;
  background: #f1c40f;
  color: #2c3e50;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.cancel-btn:hover {
  background: #f8f9fa;
}

.submit-btn:hover {
  background: #f39c12;
}

/* Remover TODOS os estilos antigos de setas */
.select-container::after,
.custom-select::after,
.form-group::after,
select::after,
.select-arrow {
  display: none !important;
  content: none !important;
}

/* Remover setas nativas dos selects */
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: none !important;
}

/* Novo estilo para o select customizado */
.custom-select {
  position: relative;
  width: 100%;
}

.select-selected {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 12px;
  border: 1px solid #e1e5eb;
  border-radius: 6px;
  background: white;
  cursor: pointer;
  transition: all 0.2s;
}

.select-selected:hover {
  border-color: #f1c40f;
}

.select-value {
  display: flex;
  align-items: center;
  gap: 8px;
}

.select-icon {
  display: flex;
  align-items: center;
  font-size: 18px;
  color: #5a6776;
}

/* Nova seta customizada usando pseudo-elemento */
.select-selected:after {
  content: '';
  width: 8px;
  height: 8px;
  border: solid #5a6776;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
  margin-left: 8px;
  display: block;
}

.select-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  background: white;
  border: 1px solid #e1e5eb;
  border-radius: 6px;
  margin-top: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.select-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.select-option:hover {
  background-color: #f8f9fa;
}

.select-option.selected {
  background-color: #f1c40f20;
}

.select-option span:last-child {
  color: #5a6776;
  font-size: 14px;
  text-transform: capitalize;
}

/* Remover setas nativas dos selects */
select {
  -webkit-appearance: none !important;
  -moz-appearance: none !important;
  appearance: none !important;
  background: none !important;
}

.application-row {
  transition: background-color 0.2s;
}

.application-row:hover {
  background-color: rgba(44, 62, 80, 0.05);
}

/* Remover os estilos da contagem de relatórios que não serão mais usados */
.report-count,
.report-count .count,
.report-count .count.has-reports {
  display: none;
}

.vulnerability-stats {
  margin-top: 2rem;
  background: white;
  border-radius: 8px;
  padding: 1.5rem;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.tabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
}

.tabs button {
  padding: 0.5rem 1rem;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 0.9rem;
  color: #666;
  border-bottom: 2px solid transparent;
  transition: all 0.2s;
}

.tabs button.active {
  color: var(--primary-color);
  border-bottom: 2px solid var(--primary-color);
}

.chart-container {
  height: 300px;
} 