.application-details-container {
  width: 100%;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
  padding: 40px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
}

.application-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
}

.application-header h1 {
  display: flex;
  align-items: center;
  gap: 12px;
  color: #2c3e50;
  font-size: 24px;
  font-weight: 600;
}

.application-favicon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.github-connect-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: #2c3e50;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}

.github-connect-btn:hover {
  background-color: #34495e;
  transform: translateY(-2px);
}

.github-connected {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 12px 24px;
  background-color: #f8f9fa;
  color: #2c3e50;
  border-radius: 8px;
  font-size: 14px;
}

.details-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-bottom: 40px;
}

.detail-card {
  background: white;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #e1e5eb;
  display: flex;
  align-items: center;
  gap: 16px;
}

.icon-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  font-size: 20px;
}

.icon-container.web-app {
  background-color: rgba(52, 152, 219, 0.1);
  color: #3498db;
}

.icon-container.mobile-app {
  background-color: rgba(46, 204, 113, 0.1);
  color: #2ecc71;
}

.icon-container.api-app {
  background-color: rgba(155, 89, 182, 0.1);
  color: #9b59b6;
}

.detail-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.detail-icon img {
  width: 24px;
  height: 24px;
}

.detail-info h3 {
  color: #5a6776;
  font-size: 14px;
  margin-bottom: 4px;
}

.detail-info p {
  color: #2c3e50;
  font-size: 16px;
  font-weight: 500;
}

.github-info {
  background: white;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid #e1e5eb;
  margin-top: 24px;
}

.github-info h2 {
  color: #2c3e50;
  font-size: 18px;
  margin-bottom: 16px;
}

.repository-details {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.repository-details p {
  color: #5a6776;
  font-size: 14px;
}

.repository-details strong {
  color: #2c3e50;
  margin-right: 8px;
}

.github-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(5px);
  padding: 20px;
}

.github-modal-content {
  background: white;
  padding: 1.5rem;
  border-radius: 12px;
  width: 90%;
  max-width: 500px;
  max-height: 85vh;
  overflow-y: auto;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2);
  position: relative;
}

.github-modal-content h2 {
  color: #2c3e50;
  font-size: 1.25rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.github-modal-content h2 svg {
  font-size: 1.75rem;
}

.github-modal-steps {
  margin: 1rem 0;
  padding: 1rem;
  background: #f8f9fa;
  border-radius: 8px;
  border: 1px solid #e9ecef;
}

.github-modal-steps h3 {
  color: #2c3e50;
  font-size: 1.1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.github-modal-steps h3 .step-number {
  background: #f1c40f;
  color: #2c3e50;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.9rem;
  font-weight: 600;
}

.form-group {
  margin: 1rem 0;
}

.form-group label {
  display: block;
  margin-bottom: 0.75rem;
  font-weight: 500;
  color: #2c3e50;
}

.form-group input {
  width: 100%;
  padding: 0.75rem;
  border: 2px solid #e9ecef;
  border-radius: 6px;
  font-size: 0.95rem;
  transition: all 0.2s ease;
}

.form-group input:focus {
  border-color: #f1c40f;
  outline: none;
  box-shadow: 0 0 0 3px rgba(241, 196, 15, 0.1);
}

.form-help-text {
  display: block;
  margin-top: 0.5rem;
  font-size: 0.85rem;
  color: #6c757d;
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 2rem;
  padding-top: 1.5rem;
  border-top: 1px solid #e9ecef;
}

.cancel-btn, .save-btn {
  padding: 0.75rem 1.5rem;
  border-radius: 6px;
  font-size: 0.95rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.cancel-btn {
  background: #f8f9fa;
  border: 1px solid #e9ecef;
  color: #2c3e50;
}

.save-btn {
  background: #2c3e50;
  border: none;
  color: white;
}

.save-btn:hover {
  background: #34495e;
  transform: translateY(-1px);
}

.save-btn:disabled {
  background: #e9ecef;
  color: #adb5bd;
  cursor: not-allowed;
  transform: none;
}

.error-message {
  margin: 1rem 0;
  padding: 1rem;
  background: #fff5f5;
  border: 1px solid #feb2b2;
  border-radius: 6px;
  color: #c53030;
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.error-message svg {
  color: #e53e3e;
  font-size: 1.1rem;
}

.permissions-list {
  margin: 0.75rem 0;
  padding: 1rem;
  background: white;
  border: 1px solid #e9ecef;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.05);
}

.permissions-list h4 {
  color: #2c3e50;
  font-size: 1rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.permissions-list h4 svg {
  color: #2c3e50;
}

.permissions-list ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.permissions-list li {
  color: #5a6776;
  font-size: 0.9rem;
  padding: 0.5rem 0;
  display: flex;
  align-items: center;
  gap: 0.75rem;
}

.permissions-list li::before {
  content: "✓";
  color: #2ecc71;
  font-weight: bold;
  background: rgba(46, 204, 113, 0.1);
  width: 20px;
  height: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
}

.github-link {
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  margin-top: 1.25rem;
  padding: 0.75rem 1.25rem;
  background: #2c3e50;
  color: white;
  text-decoration: none;
  border-radius: 6px;
  font-size: 0.9rem;
  font-weight: 500;
  transition: all 0.2s ease;
}

.github-link:hover {
  background: #34495e;
  transform: translateY(-2px);
}

.favicon-icon {
  width: 24px;
  height: 24px;
  object-fit: contain;
}

.github-modal-content::-webkit-scrollbar {
  width: 8px;
}

.github-modal-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

.github-modal-content::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

.github-modal-content::-webkit-scrollbar-thumb:hover {
  background: #a1a1a1;
}

.application-details {
  padding: 20px;
  margin-top: 60px;
}

.info-cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 30px;
}

.info-card {
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.info-card h3 {
  color: #666;
  font-size: 14px;
  margin-bottom: 10px;
}

.info-card p {
  color: #2c3e50;
  font-size: 16px;
  font-weight: 500;
}

.vulnerability-stats {
  background: white;
  border-radius: 8px;
  padding: 30px;
  box-shadow: 0 4px 6px rgba(0,0,0,0.05);
  margin: 10px auto;
  max-width: 1200px;
  width: 100%;
}

.stats-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 0 40px;
  border-bottom: 1px solid #eee;
}

.header-left {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.stats-header h2 {
  color: #2c3e50;
  font-size: 26px;
  font-weight: 600;
  letter-spacing: -0.5px;
  display: flex;
  align-items: center;
  gap: 12px;
}

.header-icon {
  color: #2c3e50;
  font-size: 24px;
  opacity: 0.9;
}

.subtitle {
  color: #666;
  font-size: 15px;
}

.tabs {
  display: flex;
  gap: 15px;
}

.tabs button {
  padding: 10px 20px;
  border: none;
  background: #f8f9fa;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #666;
  border-radius: 6px;
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.tab-content {
  display: flex;
  align-items: center;
  gap: 8px;
}

.total-count {
  background: rgba(44, 62, 80, 0.1);
  padding: 2px 8px;
  border-radius: 12px;
  font-size: 13px;
  font-weight: 600;
}

.tabs button.active {
  color: #2c3e50;
  background: #2c3e50;
  color: white;
  font-weight: 600;
}

.tabs button.active .total-count {
  background: rgba(255, 255, 255, 0.2);
  color: white;
}

.tabs button:hover:not(.active) {
  color: #2c3e50;
  background: rgba(44, 62, 80, 0.1);
}

.chart-container {
  height: 350px;
  padding: 20px 10px;
  position: relative;
  margin: 0 auto;
}

.chart-container .Bar {
  padding-top: 20px;
  padding-right: 60px;
  padding-bottom: 20px;
  padding-left: 30px;
}

.chart-legend {
  display: none;
}

.breadcrumb {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 24px;
  color: #666;
  font-size: 14px;
}

.breadcrumb a {
  color: #2c3e50;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.breadcrumb a:hover {
  color: #4a90e2;
}

.breadcrumb-separator {
  font-size: 12px;
  color: #999;
}

.breadcrumb span {
  color: #666;
}

.status-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
}

.status-icon {
  font-size: 14px;
}

.tab-icon {
  font-size: 14px;
}

/* Ajustes responsivos */
@media screen and (max-height: 800px) {
  .vulnerability-stats {
    padding: 20px;
  }

  .stats-header {
    padding: 0 0 20px;
  }

  .chart-container {
    height: 300px;
  }

  .stats-header h2 {
    font-size: 22px;
  }
}

@media screen and (max-height: 700px) {
  .vulnerability-stats {
    padding: 15px;
  }

  .chart-container {
    height: 250px;
  }
} 