* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Roboto', sans-serif;
  background-color: #ffffff;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

.reports-container {
  width: 100%;
  min-height: calc(100vh - 60px);
  margin-top: 60px;
  padding: 40px;
  background-color: #ffffff;
  overflow-y: auto;
  position: relative;
}

.dashboard {
  width: 100%;
  background: #ffffff;
  padding: 30px;
}

.dashboard h2 {
  color: #2c3e50;
  font-size: 1.8rem;
  margin-bottom: 30px;
  font-weight: 600;
  position: relative;
  padding-bottom: 15px;
}

.dashboard h2:after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 3px;
  background: #FFD700;
  border-radius: 2px;
}

.date-search {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin: 20px 0;
  gap: 12px;
  padding: 0 20px;
}

.date-search label {
  color: #5a6776;
  font-weight: 500;
}

.date-search input[type="date"] {
  padding: 10px 15px;
  border: 1px solid #e1e5eb;
  border-radius: 8px;
  background-color: #ffffff;
  font-size: 0.9rem;
  color: #2c3e50;
  transition: all 0.3s ease;
}

.date-search input[type="date"]:focus {
  border-color: #FFD700;
  box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.1);
  outline: none;
}

.date-search button {
  padding: 10px 20px;
  background-color: #FFD700;
  color: #2c3e50;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.date-search button:hover {
  background-color: #F4C430;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.detailed-list {
  margin-top: 20px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

th, td {
  padding: 12px;
  text-align: left;
  border-bottom: 1px solid #eee;
}

th {
  background-color: #ffffff;
  color: #000;
  font-weight: 500;
}

tbody tr:hover {
  background-color: #fff9e6;
  cursor: pointer;
}

.filters {
  display: flex;
  gap: 20px;
  margin: 0 0 30px 0;
  padding: 25px;
  background-color: #f8f9fa;
  border-radius: 12px;
  border: 1px solid #e1e5eb;
}

.filter {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.filter label {
  font-size: 0.9rem;
  color: #5a6776;
  font-weight: 500;
}

.filter select {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ddd;
  background-color: white;
  min-width: 200px;
}

.filter select:focus {
  border-color: #FFD700;
  box-shadow: 0 0 0 3px rgba(255, 215, 0, 0.1);
  outline: none;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 30px 0;
  gap: 12px;
}

.pagination button {
  padding: 10px 16px;
  background-color: #ffffff;
  border: 1px solid #e1e5eb;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: #2c3e50;
}

.pagination button:hover:not(:disabled) {
  background-color: #FFD700;
  border-color: #FFD700;
  transform: translateY(-1px);
}

.pagination span {
  font-weight: 500;
  color: #2c3e50;
}

.pagination button:disabled {
  background-color: #f5f5f5;
  border-color: #e1e5eb;
  color: #a0aec0;
  cursor: not-allowed;
}

@media (max-width: 768px) {
  .filters {
    flex-direction: column;
  }
  
  .filter {
    width: 100%;
  }
  
  .date-search {
    flex-wrap: wrap;
  }
}

::-webkit-scrollbar {
  width: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #f7f4e5;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #f3f0e0;
}

.reports-list {
  width: calc(100% - 80px);
  margin: 0 auto;
  background: #ffffff;
  border-radius: 12px;
  overflow: hidden;
}

.reports-list table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.reports-list th {
  background-color: #f8f9fa;
  padding: 16px;
  text-align: center;
  font-weight: 600;
  color: #2c3e50;
  border-bottom: 2px solid #e1e5eb;
  font-size: 0.95rem;
  text-transform: uppercase;
  letter-spacing: 0.5px;
}

.reports-list td {
  padding: 20px 16px;
  border-bottom: 1px solid #e1e5eb;
  color: #5a6776;
  font-size: 0.95rem;
  line-height: 1.4;
  text-align: center;
}

.reports-list tr {
  transition: all 0.2s ease;
}

.reports-list tr:hover {
  background-color: #f8f9fa;
  cursor: pointer;
}

.reports-list td:last-child {
  font-weight: 500;
}

.filter select option {
  display: flex;
  align-items: center;
  padding: 8px;
}

.filter select option svg {
  margin-right: 8px;
}

.custom-select {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 12px;
  background-color: #ffffff !important;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  cursor: pointer;
  min-height: 38px;
}

.custom-select:hover {
  border-color: var(--primary-color);
}

.custom-select.selected {
  border-color: var(--primary-color);
}

.custom-select .selected-option {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--text-color);
  background-color: #ffffff;
}

.custom-select .selected-option i {
  color: var(--text-secondary);
}

.custom-select .placeholder {
  color: var(--text-secondary);
}

.options-dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background-color: #ffffff !important;
  border: 1px solid var(--border-color);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  z-index: 1000;
  max-height: 200px;
  overflow-y: auto;
}

.options-dropdown .option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  color: var(--text-color);
  background-color: #ffffff;
}

.options-dropdown .option:hover {
  background-color: var(--hover-color);
}

.options-dropdown .option.selected {
  background-color: var(--hover-color);
  color: var(--text-color);
}

.options-dropdown .option.selected i {
  color: var(--text-color);
}

.options-dropdown .option i {
  color: var(--text-secondary);
}

.impact-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px;
}

.impact-option .icon {
  font-size: 14px;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  transition: all 0.2s ease;
}

.impact-option .icon.critical {
  color: #dc3545;
  background-color: #dc354515;
}

.impact-option .icon.high {
  color: #fd7e14;
  background-color: #fd7e1415;
}

.impact-option .icon.medium {
  color: #ffc107;
  background-color: #ffc10715;
}

.impact-option .icon.low {
  color: #28a745;
  background-color: #28a74515;
}

.impact-option:hover .icon {
  transform: scale(1.1);
}

/* Estilização do react-select */
.select__option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.select__single-value {
  display: flex !important;
  align-items: center;
  gap: 8px;
}

/* Estilo para todas as células da tabela */
.reports-list table td {
  text-align: center;
  vertical-align: middle;
}

/* Estilo específico para a célula de impacto */
.impact-cell {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}
/* Ajuste para o nome do relatório (primeira coluna) */
.reports-list table td:first-child {
  text-align: left;
}

.status-select {
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  font-size: 14px;
  width: 100%;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
}

.status-select:hover {
  border-color: #2684ff;
}

.status-select:focus {
  outline: none;
  border-color: #2684ff;
  box-shadow: 0 0 0 2px rgba(38, 132, 255, 0.2);
}

.status-display {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  padding: 6px 10px;
  border-radius: 6px;
  background-color: #f8f9fa;
  transition: all 0.2s ease;
}

.status-display i {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  transition: all 0.2s ease;
}

.status-display span {
  font-size: 13px;
  color: #172B4D;
}

/* Cores dos ícones para cada status */
.status-display.new i { 
  background-color: #2684ff15;
  color: #2684ff; 
}

.status-display.under-review i { 
  background-color: #6554C015;
  color: #6554C0; 
}

.status-display.confirmed i { 
  background-color: #FF563015;
  color: #FF5630; 
}

.status-display.awaiting-fix i { 
  background-color: #FFAB0015;
  color: #FFAB00; 
}

.status-display.in-mitigation i { 
  background-color: #36B37E15;
  color: #36B37E; 
}

.status-display.awaiting-retest i { 
  background-color: #00B8D915;
  color: #00B8D9; 
}

.status-display.retest-done i { 
  background-color: #6554C015;
  color: #6554C0; 
}

.status-display.fixed i { 
  background-color: #36B37E15;
  color: #36B37E; 
}

.status-display.closed i { 
  background-color: #6B778C15;
  color: #6B778C; 
}

tr:hover .status-display {
  background-color: #f4f5f7;
}

tr:hover .status-display i {
  transform: scale(1.1);
}

.custom-status-select {
  position: relative;
  width: 100%;
}

.status-selected {
  display: flex;
  align-items: center;
  padding: 8px 12px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: white;
  cursor: pointer;
  transition: all 0.3s ease;
  height: 40px;
}

.status-selected:hover {
  border-color: #2684ff;
}

.status-selected::after {
  content: '\f078';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  margin-left: auto;
  color: #6B778C;
  transition: transform 0.3s ease;
}

.status-options {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background: white;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
}

.status-option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  height: 40px;
}

.status-option:hover {
  background-color: #f4f5f7;
}

.status-option i {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  font-size: 12px;
  transition: all 0.2s ease;
}

.status-option span {
  font-size: 13px;
  color: #172B4D;
}

/* Cores dos ícones para cada status */
.status-option.new i { 
  background-color: #2684ff15;
  color: #2684ff; 
}

.status-option.under-review i { 
  background-color: #6554C015;
  color: #6554C0; 
}

.status-option.confirmed i { 
  background-color: #FF563015;
  color: #FF5630; 
}

.status-option.awaiting-fix i { 
  background-color: #FFAB0015;
  color: #FFAB00; 
}

.status-option.in-mitigation i { 
  background-color: #36B37E15;
  color: #36B37E; 
}

.status-option.awaiting-retest i { 
  background-color: #00B8D915;
  color: #00B8D9; 
}

.status-option.retest-done i { 
  background-color: #6554C015;
  color: #6554C0; 
}

.status-option.fixed i { 
  background-color: #36B37E15;
  color: #36B37E; 
}

.status-option.closed i { 
  background-color: #6B778C15;
  color: #6B778C; 
}

.status-option:hover i {
  transform: scale(1.1);
}

.impact-option .icon.all {
  color: #6B778C;
  background-color: #6B778C15;
}

/* Ajuste para a célula de impacto na tabela */
td .impact-option {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
}

td .impact-option .icon {
  font-size: 12px;
  width: 22px;
  height: 22px;
}

tr:hover .impact-option .icon {
  transform: scale(1.1);
}

/* Estilo para o ícone do globo na opção "Todos" */
.status-option.all i {
  background-color: #6B778C15;
  color: #6B778C;
}

.react-select-container {
  width: 100%;
  min-width: 200px;
}

.react-select__control {
  background-color: var(--background-secondary) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 4px !important;
  min-height: 38px !important;
  box-shadow: none !important;
}

.react-select__control:hover {
  border-color: var(--primary-color) !important;
}

.react-select__control--is-focused {
  border-color: var(--primary-color) !important;
  box-shadow: 0 0 0 1px var(--primary-color) !important;
}

.react-select__menu {
  background-color: var(--background-secondary) !important;
  border: 1px solid var(--border-color) !important;
  border-radius: 4px !important;
  z-index: 1000 !important;
}

.react-select__option {
  background-color: var(--background-secondary) !important;
  color: var(--text-color) !important;
  cursor: pointer !important;
  padding: 8px 12px !important;
}

.react-select__option:hover {
  background-color: var(--hover-color) !important;
}

.react-select__option--is-selected {
  background-color: var(--primary-color) !important;
  color: white !important;
}

.react-select__value-container {
  padding: 2px 8px !important;
}

.react-select__single-value {
  color: var(--text-color) !important;
}

.react-select__input {
  color: var(--text-color) !important;
}

.react-select__indicator {
  color: var(--text-color) !important;
}

.react-select__indicator:hover {
  color: var(--primary-color) !important;
}

.react-select__indicator-separator {
  background-color: var(--border-color) !important;
}

.react-select__placeholder {
  color: var(--text-secondary) !important;
}

.filter-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
  min-width: 200px;
}

.filter-group label {
  font-weight: 500;
  color: var(--text-color);
}

.select-container {
  position: relative;
  width: 100%;
}

